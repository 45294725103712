/* ////////// */
/*     MISC   */
/* ////////// */

html {  
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(43, 32, 40, 0.582),
    rgba(34, 33, 33, 0.904)
  );

  --spotify-background-color: linear-gradient(
    to bottom left,
    rgba(94, 25, 75, 0.582),
    rgba(32, 32, 32, 0.904)
  );

  --timeline-background-color: linear-gradient(
    to top right,
    rgba(57, 25, 94, 0.582),
    rgba(32, 32, 32, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.671),
    rgba(108, 16, 136, 0.623)
  );

  --portfolio-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.87),
    rgba(100, 14, 124, 0.301)
  );
}

.purple {
  color: #d345af !important;
}

.dark-purple {
  color: #C889E6 !important;
}

.purple-variation {
  color: #a84979 !important;
}

button:focus {
  box-shadow: none !important;
}

/* ////////// */
/* Loadscreen */
/* ////////// */
#preloader {
  background-color: #8b336a;
  background-image: url(./Assets/bars.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* ////////// */
/*   Scroll   */
/* ////////// */

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #532047;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b14272;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(190, 49, 166, 0.911);
  border-radius: 12px;
}

/* ////////// */
/*   Navbar   */
/* ////////// */

/* Sticked to the Page Settings */
.sticky {
  background-color: rgba(22, 21, 22, 0.562) !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(17px) !important;
  display: flex;
  flex-direction: column-reverse;
}

/* General Look Settings */
.navbar {
  background-color: rgba(22, 21, 22, 0.562) !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  font-size: 1.2rem !important;
  font-family: "Fira Sans", sans-serif;
  padding: 0.3rem 3rem !important;
  
}

.navbar-brand {
  position: absolute;
  left: 50%;
  margin-left: -50px !important;
  display: block;
}

/* Logo */
.logo {
  height: 3.2em !important;
  width: 6.0em !important;
}

.mobileLogo{
  display: none;
}

/* NAVIGATION MENU SETTINGS */
.navbar-nav .nav-link {
  color: white !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 12px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

/* Hover effect on navbar */
.navbar-nav .nav-item a:hover { 
  border-radius: 15px;
  font-size: 19.9px;
  color: #ce6090 !important;
}

/* Active Navbar Indicator (active page) */
.navbar-nav .nav-item a.active{
  color: #ce6090 !important;
}

/* NAVBAR MOBILE SETTINGS */
/* Mobile Hamburger Menu (toggler) */
.navbar-toggler {
  position: absolute !important;
  left: 83%;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #ce6090 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

/* Animation Smoothner */
.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.15s ease-in-out !important;
  transition: transform 0.15s ease-in-out !important;
}

/* Third span / bar hidden */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

/* Move bar to cross format */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  bottom: 4.5em !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  bottom: 4.5em !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

/* Navbar Phone */
@media (max-width: 950px) {
  .logoComp{
    display: none;
  }

  .navbarWeb{
    display: none;
  }

  .mobileLogo{
    display: initial;
  }

  .navbar-brand{
    position: relative;
    left: 5%;
  }

  .logo {
    left: 0px !important;
  }

  .nav-link {
    padding: 0.7rem 1rem !important;
  }

  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #14151ad7 !important;
  }

  .navbar-icons {
    display: none;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

/* ////////// */
/*   Footer   */
/* ////////// */
.footer {
  background-color: rgb(22, 21, 22);
  bottom: 0 !important;
  padding-top: 6em !important;
  padding-bottom: 4em !important ;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

.logoFooter {
  height: 5em !important;
  width: 9.0em !important;
}

.social-icons {
  display: inline-block !important;
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footerIcons:hover {
  fill: #d16191 !important;
}

/* Footer Phone */
@media (max-width: 950px) {
  .logoFooter{
    margin-bottom: 10px;
  }
  .social-icons {
    position: relative;
    margin-top: 5em;
    margin-right: 30px;
  }
}

/* ////////// */
/*  Frontpage */
/* ////////// */

/* Header Settings - Home Page */
.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: 1;
  background-image: var(--image-gradient), url(./Assets/home-bg.png);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 120px !important;
  padding-top: 120px !important;
}

.home-content {
  padding: 6rem 0 9rem !important;
}

/* Typewriter Effect */
.Typewriter__wrapper {
  font-family: "Montserrat", sans-serif;
  font-size: 3.6em !important;
  color: #ce7491 !important;
  font-weight: 750 !important;
}
.Typewriter__cursor {
  font-size: 3.8em !important;
  color: #ce7491 !important;
}

/* About me - Home Page */
.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  background-image: var(--section-background-color) !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: left;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.30em !important;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: left;
}

.skillButtons{
  padding: 8.8em;
}

/* Phone - Skill Buttons & Typewriter */
@media (max-width: 950px) {
  .skillButtons{
    padding-top: 3.5em;
    padding-bottom: 1em;
  }

  .Typewriter__wrapper {
    font-family: "Montserrat", sans-serif;
    font-size: 2em !important;
    font-weight: 650 !important;
    position: relative !important;
  }
  .Typewriter__cursor {
    font-size: 2.2em !important;
  }
}

/* BUTTONS */
/* Download CV */
.button-17 {
  background-color: #ce6090;
  border-style: none;
  cursor: pointer;
}

.button-17:hover {
  background: #F6F9FE;
  color: #ce6090;
}

/* View Projects */
.button-18 {
  background-color: #ffffff00;
  border-radius: 24px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
}

.button-18:hover {
  background: #2c2c2c;
  color: #e4e4e4;
}

.button-19 {
  background-color: #ffffff00;
  border-radius: 24px;
  border-style: solid;
  border-width: 2px;
  margin-bottom: 22px;
}

/* Universial Button Settings */
.button-17, .button-18, .button-19 {
  align-items: center;
  appearance: none;
  border-radius: 24px;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #e4e4e4;
  display: inline-flex;
  fill: currentcolor;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 58px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  margin-left: 25px;
  margin-right: 25px;
  max-width: 100%;
  overflow: visible;
  padding: 4px 48px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:active, .button-18:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus, .button-18:focus {
  outline: none;
  border: 2px solid #e4e4e4;
}

.button-17:not(:disabled), .button-18:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover, .button-18:not(:disabled):hover  {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus, .button-18:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active, .button-18:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled, .button-18:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

/* Phone Buttons Settings */
@media (max-width: 950px) {
  .button-17 {
    font-size: 17px;
    padding: 8px 68px;
    position: absolute;
  }
  .button-18 {
    margin-top: +4.5em;
    position: absolute;
  }
}

/* ////////// */
/* Portfolio  */
/* ////////// */
.project-section {
  position: relative !important;
  padding-top: 13.5em !important;
  padding-bottom: 9em !important;
  background-image: var(--section-background-color) !important;
}

.projectHeader {
  color: whitesmoke;
  font-family: "Montserrat", sans-serif;
  font-size: 3em !important;
  font-weight: 400 !important;
  padding-top: 10px !important;
}

.projectSubtext{
  color: whitesmoke;
  font-family: "Montserrat", sans-serif;
  font-size: 20px !important;
  font-weight: 300 !important;
}

/* Portolio Cards */
.project-card {
  padding-top: 50px !important;
  padding-bottom: 20px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  background-color: transparent !important;
  border: 2px solid #ebebebaf !important;
  border-radius: 40px !important;
  color: white !important;
  transition: all 0.45s ease 0s !important; 
}

.project-card-view:hover {
  overflow: hidden !important;
  transform: scale(1.03) !important;
}

/* Text Card */
.cardLeft{
  margin-left: 15px;
  text-align: left;
}

/* Image Card */
.card-img-right {
  border-radius: 40px !important;
  height: 100%;
  object-fit: cover;
  opacity: 0.85 !important;
  width: 100%;
}

/* Clickable Box Settings */
.project-card-view a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.project-card-view a:hover {
  color: #C889E6;
}

/* Portolio Cards - Buttons */
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* View Project */
.btn-secondary {
  background-color: transparent !important;
  border-color: #ffffff !important;
  border-radius: 20px !important;
  margin-bottom: 10px !important;
  width: 150px;
}

.btn-secondary:hover {
  color: white !important;
  background-color: #3c106ed7 !important; 
}

/* Github / Figma */
.btn-primary {
  background-color: #681091 !important;
  border-color: #552d75 !important;
  border-radius: 20px !important;
  margin-bottom: 10px !important;
  width: 150px;
}

.btn-primary:hover {
  background-color: #3c106ed7 !important;
  border-color: #2e0f52d7 !important;
  color: #fff !important;
}

/* /////////// */
/* Individual Portolio Pages */
/* /////////// */

/* GENERAL SETTINGS */

/* General Page Settings */
.project-section-invidual{
  position: relative !important;
  padding-bottom: 1em !important;
  background-image: var(--section-background-color) !important;
}

/* Genral Banner Settings */
.portolioBannerHauntedLegacy, .portolioBannerLucidity, .portolioBannerCharm, .portolioBannerMitis, .portolioBannerDetect{
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 23em !important;
  padding-top: 10em !important;
  z-index: 1;
}

/* Backbutton */
.backArrow{
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.backArrow:hover {
  color: #ce6090;
}

.backArrowIcon{
  border-radius: 10em;
  border-style: solid;
  font-size: 50px;
  left: 10em;
  position: absolute !important;
  top: 3.5em;
}

/* Text Banner */
.portolioHeader {
  color: whitesmoke;
  font-family: "Montserrat", sans-serif;
  font-size: 4em !important;
  font-weight: 600 !important;
  padding-top: 3.5em !important;
  padding-bottom: 10px !important;
}

/* Subtext Banner (Areas) */
.portfolioSubtext{
  color: rgba(245, 245, 245, 0.699);
  font-family: "Montserrat", sans-serif;
  font-size: 21px !important;
  font-weight: 200 !important;
  padding-bottom: 2.5em !important;
}

/* Progress Bar */
.transitionStep {
  filter: grayscale(1);
}

.transitionStep.accomplished {
  filter: grayscale(0);
}

.progressBarIcon{
  color: white;
  text-decoration: none;
}

.progressBarIcon:hover {
  color: #d345af;
}

/* Header in Text Area */
.blogHeader{
  color: whitesmoke;
  font-size: 2.5em; 
  font-family: "Lato", sans-serif;
  font-weight: 500;
  padding-bottom: 20px;
  text-align: left;
}

/* Subheader in Text Area */
.blogSubheader{
  color: whitesmoke;
  font-size: 2em; 
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: left;
}

/* Components in Text Area */
.blogComponents{
  color: whitesmoke;
  font-size: 1.2em; 
  font-family: "Lato", sans-serif;
  font-weight: 400;
  padding-bottom: 2em;
  text-align: left;
  padding-right: 30px;
}

/* Descriptions in Text Area */
.blogText{
  color: whitesmoke;
  font-size: 1.2em; 
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding-bottom: 2em;
  text-align: left;
  padding-right: 30px;
}

/* Figure Text */
.figureText{
  color: rgba(245, 245, 245, 0.863);
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 15px;
}

/* Link in text */
.linkInText{
  color: #C889E6 !important;
  font-weight: 400;
}

.linkInText:hover {
  color: #d345af !important;
}

/* Individual Settings */
/* Detect */
.portolioBannerDetect{
  background-image: var(--portfolio-gradient), url(./Assets/Projects/detectBlur.png);
}

/* Lucidity */
.portolioBannerLucidity{
  background-image: var(--portfolio-gradient), url(./Assets/Projects/lucidityBlur.png);
}

/* Haunted Legacy */
.portolioBannerHauntedLegacy{
  background-image: var(--portfolio-gradient), url(./Assets/Projects/hauntedlegacynotext.png);
}

/* Agile Picture */
@media (max-width: 950px) {
  .agilePicture{
    width: 80%;
  }
}

.hauntedLegacyIcons{
  font-size: 24px;
  color: rgb(255, 255, 255);
}

.hauntedLegacyIcons:hover {
  color: #d345af;
}

/* Charm */
.portolioBannerCharm{
  background-image: var(--portfolio-gradient), url(./Assets/Projects/charmBlur.png);
}

/* Mitis */
.portolioBannerMitis{
  background-image: var(--portfolio-gradient), url(./Assets/Projects/mitisBlur.png);
}

/* Phone Portfolio Settings */
@media (max-width: 1775px) {
  .backArrowIcon{
  font-size: 45px;
  left: 1.5em;
  /* position: fixed !important; */
  top: 4em;
  }
}

/* Phone Portfolio Settings 1250px */
@media (max-width: 1250px) {
  /* Progress Bar */
  .bar{
    display: none;
  }
}

/* ////////// */
/*  AboutPage */
/* ////////// */

/* General Container Settings */
.about-section {
  position: relative !important;
  padding-top: 160px !important;
  padding-bottom: 40px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

/* About text Settings */
.about-text{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.30em !important;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: left;
}

/* Spotify Listening Banner */
.spotifyRow{
  padding-top: 55px;
  padding-bottom: 55px;
  background-image: var(--timeline-background-color) !important;
}

/* Spotify Header Text */
.spotifyHeader{
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/* Spotify Text */
.spotifyText{
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 250;
  padding-bottom: 20px;
}

.skill-heading{
  color: white !important;
  font-size: 2.5em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* Skill Icon Cards */
.skill-icons {
  border: 1.6px solid #d345af !important;
  border-radius: 1em !important;
  color: #d345af;
  display: table !important;
  font-size: 2.5em !important;
  margin: 10px !important;
  padding: 8px !important;
  overflow: hidden !important;
}

/* Tool Icon Cards */
.tool-icons {
  border: 1.6px solid #C889E6 !important;
  border-radius: 1em !important;
  color: #C889E6;
  display: table !important;
  font-size: 2.5em !important;
  margin: 10px !important;
  padding: 8px !important;
  overflow: hidden !important;
}

.skillCardText{
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 18px;
  color: #e8ddee;
}

.timelineGraph{
  /* background-image: var(--timeline-background-color) !important; */
  color: #994383;
}

.experienceTitle{
  color: white !important;
  font-size: 2.5em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

@media (max-width: 950px) {
  .about-img {
    padding-top: 20px !important;
  }

  .skillRow{
    width: 70%;
    margin-left: 3.5em !important;
  }
}

/* ////////// */
/*  Spotify */
/* ////////// */

.nowPlayingCard {
  flex-shrink: 0;
  align-items: center;
  border: 2px ridge #f7e7e7cc;
  border-radius: 12px;
  display: flex;
  height: 95px;
  justify-content: space-between;
  margin: 0 auto;
  transition: all 0.5s ease;
  width: 450px;
}

.nowPlayingCard:hover {
  transform: translateX(-1.25px) translateY(-1.25px);
  background-color: rgba(0, 0, 0, 0.199);
  transition: all 0.5s ease;
}

.nowPlayingCard a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.nowPlayingCard a:hover {
  color: #C889E6;
}

.nowPlayingImage img {
  border-radius: 8px;
  transition: all 0.5s ease;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  margin-bottom: 1px;
  margin-right: 5px;
}

#nowPlayingDetails {
  justify-content: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 54%;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 54%;
  height: 100%;
}

.nowPlayingTitle, .playlistName {
  flex-shrink: 0;
  color: #ffffff;
  white-space: nowrap;
  text-align: left;
  font-size: 20px;
  width: 100%;
}

.nowPlayingArtist, .playlistHeader {
  text-align: left;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.nowPlayingTime {
  color: #C889E6;
  text-align: left;
}

.nowPlayingState {
  text-align: center;
  width: 20%;
  padding: 10px;
}

.nowPlayingState img{
  width: 60%;
}

/* Phone Buttons Settings */
@media (max-width: 950px) {
  .nowPlayingCard{
    width: 80vw;
  }
  .spotifyIconMobile{
    width: 8vw;
  }
}
